import { Link } from 'gatsby';
import styled from 'styled-components';
import { colorPalette } from '../../styles/colorSchema';

export const Container = styled.div`
  display:flex;

  border: 1px solid ${colorPalette.disabledColor};
  margin-top:30px;
  border-radius:5px;
  padding-right:5px;
  align-items:center;
  background-color:${colorPalette.white};
  margin:30px 200px 0 200px;
    @media(max-width:764px){
      margin:30px 40px 0 40px;
    }
`;

export const Img = styled.img`
  min-width:5px;
  margin:2px;
  opacity:0.5;
  :hover{
    opacity:1.0;
  }
`;
export const Nav = styled(Link)`
  display:flex;
  align-items:center;
  
`;

export const Input = styled.input`
  flex:1;
  border: none;
  padding-left:20px;
  outline:none;
  min-height:44px;
  border-radius:5px;
  font-size:18px;

  :text{
    font-size:18px;
  }
`;
