import React from 'react'
import links from './content'
import { MenuLinksItem, MenuLinksLink, MenuLinksList } from './styled'

export default function MenuLinks() {
  return (

    <MenuLinksList>
      {links.map((link, i) => {
        return (
          <MenuLinksItem key={i}>
            <MenuLinksLink to={link.url}>{link.label}</MenuLinksLink>
          </MenuLinksItem>
        )
      })}
    </MenuLinksList>

  )
}
