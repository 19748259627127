import { Link } from 'gatsby';
import styled from 'styled-components';
import { colorPalette } from '../../styles/colorSchema';

export const MenuLinksList = styled.ul`
  display:flex;
  text-decoration:none;
  
`;

export const MenuLinksItem = styled.li`
  text-decoration:none;
    list-style:none;
`;

export const MenuLinksLink = styled(Link)`
  color: ${colorPalette.white};
  text-decoration:none;
  list-style:none;
  margin:8px;
  transition: color 0.5s;

  &:hover{
    color: ${colorPalette.white};
    opacity:0.3;
  }
`;