const links = [
  {
    label: "Home",
    url: "/"
  },
  {
    label: "Fale conosco",
    url: "/suporte"
  },
]
export default links;