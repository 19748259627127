import { Link } from 'gatsby';
import styled from 'styled-components';
import { colorPalette } from '../../styles/colorSchema';

export const Wrapper = styled.div`
  display:flex;
  justify-content:space-between;
  
  align-items:center;
  background-color:${colorPalette.primary};
`;
export const Menu = styled.div`
  display:flex;
`;
export const Contact = styled(Link)`
  outline:none;
  margin:5px;
  text-decoration:none;
  color: white;
  padding: 5px 0px;
  font-size: 16px;
  cursor: pointer;
  background-color:${colorPalette.primary};
  :hover {
    background-color: ${colorPalette.primary};
    opacity:0.3;
    color: ${colorPalette.white};
  }
`;

export const Img = styled.img`
  min-width:10px;
  margin:5px;
`;

export const Title = styled.text`
  color: ${colorPalette.white};
  text-align:center;
  margin-left:10px;

  font-weight: ${({ bold }) => bold ? '700' : '300'};
`;

export const Products = styled.button`
  background-color:${colorPalette.primary};
  margin-left:38px;
  border:none;
  outline:none;
  cursor: pointer;
  border-bottom: ${({ active }) => active ? `2px solid ${colorPalette.white}` : ` ${colorPalette.secondary}`};
  
  :hover{
    transition: border-width 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    border-bottom: 2px solid ${colorPalette.white};
    text{
      transition: color 0.6s linear;
      color:${colorPalette.white};
    }
  }
`;
