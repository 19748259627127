import React, { useState } from 'react';

import { Container, Input, Nav, Img } from './styled'
import { useDispatch } from 'react-redux';
import { setStringSearch as creators } from '../../state/app';
import searchIcon from '../../images/search-icon-black.svg'
export default function SearchLink() {

  const [data, setData] = useState();
  const dispatch = useDispatch();

  function handleChange(event) {
    setData({ value: event.target.value });
  }

  function handleSubmit() {
    if (data.value.length > 0) {
      dispatch(creators(data.value));
    }
  }

  return (
    <Container>
      <Input name="Buscar" placeholder="Buscar" onChange={handleChange} />
      <Nav to="/searchPage">
        <div onClick={handleSubmit}>
          <Img src={searchIcon} />
        </div>
      </Nav>
    </Container>
  )
}
