import React, { useState } from 'react'
import { Body, Container, Header, Nav, Wrapper, WrapperSearch, Img } from './styled';
import { Modal } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { colorPalette } from '../../styles/colorSchema';
import SearchLink from '../search-link';
import { useSelector } from 'react-redux';
import minilogoIcon from '../../images/mini-logo.svg';
import closeIcon from '../../images/close-icon.svg';

export default function MenuMobile() {

  const [open, setOpen] = React.useState(false);
  const { product } = useSelector(state => state.app);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <button onClick={handleOpen}>
        <MenuIcon fontSize="large" style={{ backgroundColor: colorPalette.primary, color: colorPalette.white, width: 25 }} />
      </button>
      <Modal
        open={open}
        onClose={handleClose} >
        <Wrapper>
          <Header>
            <Img src={minilogoIcon} />
            <text>{product}</text>
            <Img src={closeIcon} onClick={handleClose} />
          </Header>
          <Body>
            <WrapperSearch>
              <SearchLink />
            </WrapperSearch>
            <Nav to="/" onClick={handleClose}>
              Home
            </Nav>
            <Nav to="/" onClick={handleClose}>
              Produtos V4H
            </Nav>
            <Nav to="/suporte" onClick={handleClose}>
              Fale conosco
            </Nav>
          </Body>
        </Wrapper>
      </Modal>
    </Container>
  )
}
