import { Link } from 'gatsby';
import styled from 'styled-components';
import { colorPalette } from '../../styles/colorSchema';

export const Container = styled.div`

  background-color:${colorPalette.primary};
  button{
    background-color:${colorPalette.primary};
    outline:none;
    text-decoration:none;
    border:0;
  }
`;



export const Nav = styled(Link)`
  color: #000;
  text-decoration:none;
  font-size:18px;
`;

export const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
`;
export const Header = styled.div`
  display:flex;
  flex:1;
  font-size:18px;
  font-weight:bold;
  color:${colorPalette.white};
  padding:10px 10px 10px 10px;
  justify-content:space-between;
  align-items:center;
  background-color:${colorPalette.primary};
  height:30px;
`;

export const Body = styled.div`
  display:flex;
  height:300px;
  background-color:${colorPalette.white};
  justify-content:space-around;
  flex-direction:column;
  align-items:center;
  padding-bottom:10px;
`;

export const WrapperSearch = styled.div`
    display:flex;
    align-items:center;
    /* border:1px solid black; */
    /* box-shadow: 2px 2px 2px 2px ${colorPalette.disabledColor}; */
    justify-content:center;
    height:44px;
`;
export const Img = styled.img`
  min-width:5px;
  margin:2px;
`;