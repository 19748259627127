import { useMediaQuery } from '@material-ui/core';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import MenuLinks from '../menu-links';
import MenuMobile from '../menu-mobile';

import { Contact, Title, Wrapper, Menu, Img } from './styled'
import searchIcon from '../../images/search-icon.svg';
import logoIcon from '../../images/mini-logo.svg'


export default function NavBar({ isFaq = false }) {
  const [iconSearch, setIcon] = useState(false);
  const isMobile = useMediaQuery('(max-width:760px)');
  const { product } = useSelector(state => state.app);
  return (
    <Wrapper>
      {isMobile && <Img src={logoIcon} />}
      <Contact to="/">
        {isMobile && isFaq ? <Title bold>{product}</Title> : <Title bold>Central de ajuda</Title>}
      </Contact>
      <Menu>
        {isMobile ? <MenuMobile /> :
          <MenuLinks />
        }
      </Menu>
    </Wrapper>
  )
}
